import { Card, CardActionArea, CardContent, Chip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function TournamentCard({ tournament }) {
  const [userId, setUserId] = useState("");
  const history = useHistory();

  useEffect(() => {
    setUserId(localStorage.getItem('loggedInUserId'));
  }, []);

  return (
    <Card
      elevation={0}
      style={{ border: '1px solid var(--neutral-white)', borderRadius: '8px', boxShadow: '0px 4px 4px 0px #EDE8EC14' }}
    >
      <CardActionArea onClick={() => history.push(`/tournament/info/${tournament._id}`)}>
        <CardContent>
          {(tournament.createdBy === userId && tournament?.admins?.some(admin => admin === userId)) ? (
            <Chip color="primary" size="small" label="Owner & Admin" />
          ) : tournament?.createdBy === userId ? (
            <Chip color="primary" size="small" label="Owner" />
          ) : tournament?.admins?.some(admin => admin === userId) && (
            <Chip color="primary" size="small" label="Admin" />
          )}
          <div className="flex-between g-05">
            <p className='text-primary' style={{ fontSize: '22px', fontWeight: 400 }}>{tournament.name}</p>
            <p>{tournament.startDate}</p>
          </div>
          <p className='mt-0'>{tournament.ground}</p>
          {tournament.status === 'NOT-STARTED' ? (
            <>
              <p>Registered Teams: {tournament.registrationRequests.length} / {tournament.teamCount}</p>
              <p>Registration Deadline: {tournament.registrationDeadline}</p>
            </>
          ) : (
            <p>Completed Matches: {tournament.fixtures.filter((fixture) => fixture.status === 'STARTED' || fixture.status === 'COMPLETED').length} / {tournament.fixtures.length}</p>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default TournamentCard