import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../CustomMUI/CustomButtons";
import { ShowToast, ToastMessage } from "../CustomMUI/ToastMessage";
import { Card, CardContent, CardActions } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { txt } from "../../common/context";
import CustomTextField from "../CustomMUI/CustomTextField";
import "./team.css";
import CustomBottomSheet from "../CustomMUI/CustomBottomSheet";
import Header from "../CustomMUI/Header";
import TeamIcon from "../../assets/images/svg/team.svg";
import { CustomAddPlayerButton } from "../CustomMUI/CustomSmallButton";
import AddPlayersModal from "../Player/AddPlayersModal";
import CustomCard from "../CustomMUI/CustomCard";
import { BASE_URL, sendHttpRequest } from "../../common/Common";
import CustomRMPlayerBottomSheet from "../CustomMUI/CustomRMPlayerBottomSheet";
import CreatePlayer from "../Player/CreatePlayer";

const CreateTeam = () => {
  const history = useHistory();
  const [teamName, setTeamName] = useState("");
  const [teamLocation, setTeamLocation] = useState("");
  const [teamError, setTeamError] = useState("");
  const [teamLocationError, setTeamLocationError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [createPlayerModal, setCreatePlayerModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRemovePlayer, setIsRemovePlayer] = useState(false);
  const [IsValidPlayerList, setIsValidPlayerList] = useState(false);

  const [allPlayerList, setAllPlayerList] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedRmPlayer, setSelectedRmPlayer] = useState();

  useEffect(() => {
    getPlayer();
  }, []);

  const getPlayer = () => {
    sendHttpRequest("GET", `${BASE_URL}/api/player`)
      .then((res) => {
        if (res.data) {
          let allPlayer = res.data.result.map((obj) => {
            return {
              ...obj, // Spread operator to copy the existing properties
              isVerified: false, // New parameter to add
            };
          });
          const combinedPlayerList = [...allPlayer];
          const storedTeamData = localStorage.getItem("teamData");
          if (storedTeamData) {
            const parsedTeamData = JSON.parse(storedTeamData);
          
            if (parsedTeamData.PlayerList) {
              const storedPlayerList = parsedTeamData.PlayerList;
                       
              storedPlayerList.forEach((storedPlayer) => {
                const existsInAPI = combinedPlayerList.find(
                  (apiPlayer) => apiPlayer._id === storedPlayer._id
                );
                if (existsInAPI) {
                  existsInAPI.isVerified = storedPlayer.isVerified;
                } else {
                  combinedPlayerList.push(storedPlayer);
                }
              });
            }
          }
          setAllPlayerList(combinedPlayerList);
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (teamName && teamLocation) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [teamName, teamLocation]);

  const handleSubmit = () => {
    setIsLoading(true);
    if (!teamName) {
      setTeamError(txt.please_enter_valid_email_address);
    } else {
      setTeamError("");
    }
    if (!teamLocation) {
      setTeamLocationError(txt.location_required);
    } else {
      setTeamLocationError("");
    }
    if (isFormValid) {
      setIsShow(true);
    }
    setIsLoading(false);
  };

  const removePlayer = () => {
    let data = allPlayerList.map((item) => {
      if (item._id == selectedRmPlayer._id) {
        return {
          ...item, // Spread operator to copy the existing properties
          isVerified: false, // New parameter to add
        };
      } else {
        return item;
      }
    });
    const storedData = JSON.parse(localStorage.getItem("teamData")) || {};
    storedData.PlayerList = data;
    localStorage.setItem("teamData", JSON.stringify(storedData)); 

    setAllPlayerList(data);
    setIsRemovePlayer(false);
    setSelectedRmPlayer();
  };

  useEffect(() => {
    // if (teamMembers.length > 5 && teamMembers.length < 17) {
    if (teamMembers.length > 1 && teamMembers.length < 17) {
      setIsValidPlayerList(true);
    } else setIsValidPlayerList(false);
  }, [teamMembers]);

  useEffect(() => {
    // Load data from localStorage
    const storedData = JSON.parse(localStorage.getItem("teamData")) || {};
    const storedPlayerList = storedData.PlayerList || [];

    // On first load, check if there is any data in localStorage
    if (storedPlayerList.length > 0) {
      setTeamMembers(storedPlayerList.filter((item) => item.isVerified));
    } else {
      // If no localStorage data, use allPlayerList as it is
      setTeamMembers(allPlayerList.filter((item) => item.isVerified));
    }
  }, []);

  useEffect(() => {
    if (allPlayerList.length > 0) {
      // Load existing localStorage data
      const storedData = JSON.parse(localStorage.getItem("teamData")) || {};
      const storedPlayerList = storedData.PlayerList || [];

      const finalPlayerList = allPlayerList.map((player) => {
        // Find corresponding player in storedPlayerList using _id
        const storedPlayer = storedPlayerList.find((p) => p._id === player._id);

        if (storedPlayer) {
          // Compare isVerified values
          if (storedPlayer.isVerified || player.isVerified) {
            // If either one is true, return the object with isVerified: true
            return storedPlayer.isVerified ? storedPlayer : player;
          }
        }
        // If no match or both are false, return the original player (any object)
        return player;
      });

      setTeamMembers(finalPlayerList.filter((item) => item.isVerified));

      storedData.PlayerList = finalPlayerList;
      localStorage.setItem("teamData", JSON.stringify(storedData));
    }
  }, [allPlayerList]);

  const onPressCreateTeam = () => {
    console.log("create team API");
    if (teamMembers.length < 5) {
      ShowToast("Add at least 6 players before creating a team", {
        position: "top-right",
        type: "error",
      });
      return;
    }
    if (teamMembers.length > 17) {
      ShowToast("Team can have only 16 players", {
        position: "top-right",
        type: "error",
      });
      return;
    }

    let data = {
      name: "Pool - " + teamName,
      players: teamMembers.map((item) => item._id),
      createdBy: localStorage.getItem("loggedInUserId"),
      owner: localStorage.getItem("loggedInUserId"),
      isTemp: false,
    };

    sendHttpRequest("POST", BASE_URL + "/api/pool", null, JSON.stringify(data))
      .then((res) => {
        if (res.data.data) {
          let data1 = {
            teamName: teamName,
            teamMembers: teamMembers.map((item) => item._id),
            createdBy: localStorage.getItem("loggedInUserId"),
            poolId: res.data.data._id,
            teamLocation: teamLocation,
            isTemp: false,
            owner: localStorage.getItem("loggedInUserId"),
          };
          sendHttpRequest(
            "POST",
            BASE_URL + "/api/team",
            null,
            JSON.stringify(data1)
          )
            .then((res) => {
              setIsOpen(true);
              localStorage.removeItem("teamData");
              console.log("res", res);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const storedTeamData = localStorage.getItem("teamData");

    if (storedTeamData) {
      const parsedTeamData = JSON.parse(storedTeamData);

      if (parsedTeamData.teamName) {
        setTeamName(parsedTeamData.teamName);
      }

      if (parsedTeamData.teamLocation) {
        setTeamLocation(parsedTeamData.teamLocation);
      }

      if (parsedTeamData.PlayerList) {
        setAllPlayerList(parsedTeamData.PlayerList);
      }
    }
  }, []);

  return (
    <>
      {!isShow ? (
        <div className="pageMainContainer" style={{}}>
          <Header title={txt.create_team} />
          <ToastMessage />
          <Card
            style={{ flex: 1, overflowY: "scroll" }}
            className="card card-padding"
            elevation={0}
          >
            <CardContent>
              <div className="profile-pic-container">
                <img src={TeamIcon} className="profile-pic" />
              </div>
              <h1 className="text-left">{txt.create_team}</h1>
              <p className="text-left text-color">
                {txt.start_by_entering_the_details_of_your_team}
              </p>

              <CustomTextField
                label={txt.team_name}
                type="text"
                value={teamName}
                onChange={(e) => {
                  setTeamName(e.target.value);
                  setTeamError("");
                  const teamData =
                    JSON.parse(localStorage.getItem("teamData")) || {};
                  teamData.teamName = e.target.value;
                  localStorage.setItem("teamData", JSON.stringify(teamData));
                }}
              />
              {teamError && <p className="error-message">{teamError}</p>}

              <CustomTextField
                label={txt.location}
                type="text"
                value={teamLocation}
                onChange={(e) => {
                  setTeamLocation(e.target.value);
                  setTeamLocationError("");
                  const teamData =
                    JSON.parse(localStorage.getItem("teamData")) || {};
                  teamData.teamLocation = e.target.value;
                  localStorage.setItem("teamData", JSON.stringify(teamData));
                }}
              />
              {teamLocationError && (
                <p className="error-message">{teamLocationError}</p>
              )}

              <div style={{ opacity: isFormValid ? 1 : 0.2 }}>
                <h2 className="text-left">{txt.add_players}</h2>
                <p className="text-left text-color">
                  {txt.players_of_your_team_will_be_listed_here}
                </p>
                {!teamMembers.length ? (
                  <CardActions className="card-actions">
                    <CustomAddPlayerButton
                      name={txt.add_players}
                      onClick={handleSubmit}
                      disabled={!isFormValid}
                    />
                  </CardActions>
                ) : (
                  <div style={{ paddingTop: 10 }}>
                    {teamMembers?.map((item, index) => {
                      return (
                        <div key={item._id}>
                          <CustomCard
                            from={"createTeam"}
                            player={item}
                            name={item.isVerified ? "Verified" : "Verify"}
                            onClick={(value) => {
                              setIsRemovePlayer(true);
                              setSelectedRmPlayer(value);
                              console.log("clickVerify", value);
                            }}
                          />
                        </div>
                      );
                    })}
                    <CardActions className="card-actions">
                      <CustomAddPlayerButton
                        name={txt.add_more_player}
                        onClick={handleSubmit}
                        disabled={!isFormValid}
                      />
                    </CardActions>
                    <div
                      className="button-container"
                      style={{
                        opacity: IsValidPlayerList ? 1 : 0.2,
                      }}
                    >
                      <PrimaryButton
                        onClick={onPressCreateTeam}
                        disabled={IsValidPlayerList ? false : true}
                      >
                        {txt.create_team}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
              </div>

              <CustomBottomSheet
                isOpen={isOpen}
                onDismiss={() => {
                  setIsOpen(false);
                  history.goBack();
                }}
                onButton1Click={() => {
                  setIsOpen(false);
                  history.goBack();
                }}
                messageText={teamName + " " + txt.created_Successfully}
                button1Text={txt.continue}
              />

              <CustomRMPlayerBottomSheet
                isOpen={isRemovePlayer}
                onDismiss={() => {
                  setIsRemovePlayer(false);
                  setSelectedRmPlayer();
                }}
                player={selectedRmPlayer}
                onButton1Click={removePlayer}
                onButton2Click={() => {
                  setIsRemovePlayer(false);
                  setSelectedRmPlayer();
                }}
              />
            </CardContent>
          </Card>
        </div>
      ) : createPlayerModal ? (
        <CreatePlayer
          isModal={createPlayerModal}
          closeModal={() => setCreatePlayerModal(false)}
        />
      ) : (
        <AddPlayersModal
          show={isShow}
          setIsShow={setIsShow}
          allPlayerList={allPlayerList}
          setAllPlayerList={setAllPlayerList}
          setCreatePlayerModal={setCreatePlayerModal}
        />
      )}
    </>
  );
};

export default CreateTeam;
