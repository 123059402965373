import { Avatar, Card, CardContent } from '@material-ui/core';
import React from 'react'
import './TeamInfoCard.css';

const TeamInfoCard = ({ teamInfo , setSelectedTeam }) => {

  return (
    <Card className='team-info-card' elevation={0} onClick={() =>setSelectedTeam(teamInfo)}>
      <CardContent style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '15px' }}>

          <Avatar src='A' alt={teamInfo?.teamName} variant="rounded" className='avatar' />

          <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: '10px' }}>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '6px' }}>

              <p className='f1520600 text-container'>{teamInfo?.teamName}</p>

              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                <p className='f1113400 text-container'>{teamInfo?.location}</p>
                <p className='f1113400 text-container'>{teamInfo?.joindeDate}</p>
              </div>

            </div>


            {
              teamInfo?.createdBy &&
              <div>
                <p className='f1318400 isAdmin'>Admin</p>
              </div>
            }

          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
            <p className='f1113400 text-container'>Played</p>
            <p className='f1113400 text-container' style={{ background: 'var(--bg8)' , color : '#fff' }}>{teamInfo?.played}</p>
          </div>
          <span className='vertical-line'></span>
          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
            <p className='f1113400 text-container'>Won</p>
            <p className='f1113400 text-container' style={{ background: 'var(--bg2)' , color : '#fff' }}>{teamInfo?.won}</p>
          </div>
          <span className='vertical-line'></span>

          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
            <p className='f1113400 text-container'>Lost</p>
            <p className='f1113400 text-container' style={{ background: 'var(--delete-btn-bg)' , color : '#fff' }}>{teamInfo?.lost}</p>
          </div>
          <span className='vertical-line'></span>
          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
            <p className='f1113400 text-container'>Win %</p>
            <p className='f1113400 text-container' style={{ background: 'var(--bg2)' , color : '#fff' }}>{teamInfo?.win}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default TeamInfoCard;