import React, { useEffect, useState } from 'react'
import { BASE_URL, sendHttpRequest } from '../../../common/Common';
import { toast } from 'react-toastify';
import { Grid, LinearProgress } from '@material-ui/core';
import NoResults from '../../../components/NoResults';
import TournamentCard from '../../../components/tournaments/TournamentCard';

function OtherTournaments() {
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTournaments();
  }, []);

  function getTournaments() {
    setIsLoading(true);
    sendHttpRequest("GET", BASE_URL + "/api/tournament/open/" + localStorage.getItem('loggedInUserId')).then((res) => {
      setTournaments(res.data.data);
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) {
    return <LinearProgress />;
  } else if (tournaments.length === 0) {
    return <NoResults text="tournaments" />
  }

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        {tournaments.map((tournament, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <TournamentCard tournament={tournament} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default OtherTournaments