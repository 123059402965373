import { Card, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { PrimaryButton } from '../../../components/CustomMUI/CustomButtons'
import { BASE_URL, sendHttpRequest, WEB_BASE_URL } from '../../../common/Common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function Details({ tournamentDetails, refreshData }) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const userId = localStorage.getItem('loggedInUserId');

  function startTournament() {
    const data = {
      tournamentId: tournamentDetails._id,
      userId: userId
    }

    setIsLoading(true);
    sendHttpRequest("POST", BASE_URL + "/api/tournament/" + tournamentDetails.tournamentType, null, JSON.stringify(data)).then(res => {
      refreshData();
    }).catch((error) => {
      toast.error(error.response.data.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div>
      <Card className="card">
        <h2 className="text-center text-primary my-0">
          {tournamentDetails.name} <br />
        </h2>
        <h3 className="text-center text-primary my-0">
          {tournamentDetails.matchList?.length} matches | {tournamentDetails.teamList?.length} / {tournamentDetails.teamCount} Teams
        </h3>
        <hr />
        <div className="flex-between">
          <div className="flex-center-vertical text-primary">
            <span> Date: {tournamentDetails.date} </span>
            <span> Ground: {tournamentDetails.ground} </span>
            <div> Pitch Type: {tournamentDetails.pitchType}</div>
          </div>
          <div className="flex-center-vertical text-primary">
            <span> Category: {tournamentDetails.tournamentType} </span>
            <span> Ball: {tournamentDetails.ballType} </span>
            <div> Overs: {tournamentDetails.overs}</div>
          </div>
        </div>
      </Card>
      {(tournamentDetails.createdBy?._id === userId || tournamentDetails.admin?._id === userId) &&
        <div className="bottom-center flex-around blur-bg py-15 px-15" style={{ gap: '0.5rem' }}>
          <PrimaryButton onClick={() => { history.push(`/tournament/settings/${tournamentDetails._id}`) }}>
            Settings
          </PrimaryButton>
          {tournamentDetails.teamCount !== tournamentDetails.teamList?.length ? (
            <PrimaryButton
              onClick={() => {
                navigator.clipboard.writeText(`${WEB_BASE_URL}/tournament/register/${tournamentDetails._id}`)
                toast.success("Link copied to clipboard")
              }}
            >
              Invite Team
            </PrimaryButton>
          ) : tournamentDetails.status === "NOT-STARTED" ? (
            <PrimaryButton onClick={() => startTournament()} endIcon={isLoading && <CircularProgress size={'1.5rem'} />}>
              Start Tournament
            </PrimaryButton>
          ) : null}
        </div>
      }
    </div>
  )
}

export default Details