import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import BottomDrawer from '../BottomDrawer/BottomDrawer'
import { BYE, LEG_BYE, NO_BALL, WIDE_BALL } from './ScoreSheetModal'
import { CustomCardButton } from '../CustomMUI/CustomSmallButton'
import './BallTypeRunsBottomSheet.css'

const BallTypeRunsBottomSheet = ({ isOpen, onDismiss, ballType, handleBall , icon }) => {
    return (
        <BottomDrawer isOpen={isOpen} onDismiss={onDismiss} title={ballType} providedIcon={icon}>
            <div className='BallTypeRunsBottomSheet' style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
                {
                    ballType === WIDE_BALL ? (
                        [...Array(7)]?.map((_, idx) => (
                            <CustomCardButton
                                key={idx}
                                // active={selectedBallType ? selectedBallType === ball ? true : false : false}
                                onClick={() => {
                                    handleBall(idx);
                                    onDismiss();
                                }}
                                name={`WB + ${idx}`}
                            />
                        ))
                    ) : ballType === NO_BALL ? (
                        [...Array(7)]?.map((_, idx) => (
                            <CustomCardButton
                                key={idx}
                                onClick={() => {
                                    handleBall(idx);
                                    onDismiss();
                                }}
                                name={`NB + ${idx}`}
                            />
                        ))
                    ) : ballType === BYE ? (
                        [...Array(6)]?.map((_, idx) => (
                            idx !== 0 && (
                                <CustomCardButton
                                    key={idx}
                                    onClick={() => {
                                        handleBall(idx);
                                        onDismiss();
                                    }}
                                    name={`B + ${idx}`}
                                />
                            )
                        ))
                    ) : ballType === LEG_BYE && (
                        [...Array(6)]?.map((_, idx) => (
                            idx !== 0 && (
                                <CustomCardButton
                                    key={idx}
                                    onClick={() => {
                                        handleBall(idx);
                                        onDismiss();
                                    }}
                                    name={`LB + ${idx}`}
                                />
                            )
                        ))
                    )
                }
            </div>
        </BottomDrawer>
    )
}

export default BallTypeRunsBottomSheet