import { Avatar, Card } from '@material-ui/core'
import React from 'react'
import './PlayerCardWithScore.css'

const PlayerCardWithScore = ({ playerInfo, indexNum = 0 }) => {
    return (
        <div className='palyer-card-with-score'>
            <p className={`${playerInfo?.score > 200 ? 'f2632500' : 'f2025400'}`} style={{ width: '2.375rem', }} >{String(++indexNum).padStart(2, '0')}</p>
            <Avatar className='avatar' />
            <div>
                <p className='f1318400'>{playerInfo?.firstName}</p>
                <p className='f1113400' style={{ color: 'var(--color1)' }}>Highest In A Match: {playerInfo?.matches}</p>
            </div>
            <p
                className={`score-container ${playerInfo?.score > 200 ? 'f3239400' : 'f2632500'}`}
                style={{ background: playerInfo?.score > 200 ? 'var(--bg10)' : 'var(--card-1-bg)' }}
            >
                {playerInfo?.score}
            </p>
        </div>
    )
}

export default PlayerCardWithScore