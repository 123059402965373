import { Avatar, Card, Grid } from '@material-ui/core'
import React from 'react'
import { CustomCardButton } from './CustomSmallButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const TeamCard = ({ teamData, handleAction, btnTitle }) => {
	const history = useHistory();

	return (
		<Card elevation={0} style={{ background: 'transparent' }}>
			<Grid container alignItems="center" spacing={2}>
				<Grid item onClick={() => history.push(`/team/${teamData._id}`)}>
					<Avatar
						src={teamData?.logo}
						alt={teamData?.name}
						sx={{ width: 60, height: 60 }}
					/>
				</Grid>
				<Grid item xs onClick={() => history.push(`/team/${teamData._id}`)}>
					<div style={{ color: "#000", fontFamily: "DM Sans", fontWeight: 400, fontSize: "15px" }}>
						{teamData?.name}
					</div>
					<div style={{ color: "#89898D", fontFamily: "DM Sans", fontWeight: 400, fontSize: "13px" }}>
						{teamData?.location}
					</div>
				</Grid>
				{handleAction ? (
					<Grid item>
						<CustomCardButton
							onClick={() => handleAction(teamData)}
							name={btnTitle}
						/>
					</Grid>
				) : (
					<Grid item>
						<CustomCardButton
							onClick={() => history.push(`/team/${teamData._id}`)}
							name='View'
						/>
					</Grid>
				)}
			</Grid>
		</Card>
	)
}

export default TeamCard