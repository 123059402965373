import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  CircularProgress,
} from "@material-ui/core";
import { sendHttpRequest, BASE_URL, isEmail, isContactNo } from "../../common/Common";
import logo from "../../assets/images/FieldR_Logo_Blue.png";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import "./auth.css";
import { ShowToast, ToastMessage } from "../CustomMUI/ToastMessage";
import CustomTextField from "../CustomMUI/CustomTextField";
import CustomPasswordField from "../CustomMUI/CustomPasswordField";
import { PrimaryButton } from "../CustomMUI/CustomButtons";
import { txt } from "../../common/context";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [emailOrContactNo, setEmailOrContactNo] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailOrContactNoError, setEmailOrContactNoError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { login } = useAuth();
  const history = useHistory();

  const handleGoogleLoginSuccess = async (res) => {
    setIsLoading(true);

    const data = {
      googleToken: await res.credential,
    };

    sendHttpRequest("POST", BASE_URL + "/api/auth/google-login", null, JSON.stringify(data)).then((res) => {
      login(res.data.data.token, res.data.data._id, res.data.data);
      setIsLoading(false);
      history.push("/home");
    }).catch((error) => {
      setIsLoading(false);
      toast.error(error.response.data.message);
    });
  };

  const handleGoogleLoginFailure = (res) => {
    toast.error(res.error);
  };

  const handleLogin = async () => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    // Reset error messages
    setEmailOrContactNoError("");
    setPasswordError("");

    if (!emailOrContactNo) {
      setEmailOrContactNoError(txt.enter_your_email_or_your_phone_number);
      return;
    }
    if (!password) {
      setPasswordError(txt.enter_password);
      return;
    }
    if (!passwordPattern.test(password)) {
      setPasswordError(txt.password_invalid_format);
      return;
    }

    let params = {};
    if (emailOrContactNo.includes("@")) {
      if (!isEmail(emailOrContactNo)) {
        setEmailOrContactNoError(txt.please_enter_valid_email_address);
        return;
      } else {
        params = {
          email: emailOrContactNo,
          password: password,
        };
      }
    } else {
      if (!isContactNo(emailOrContactNo)) {
        setEmailOrContactNoError(txt.please_enter_valid_contact_number);
        return;
      } else {
        params = {
          contactNo: emailOrContactNo,
          password: password,
        };
      }
    }

    setIsLoading(true);

    try {
      const res = await sendHttpRequest(
        "POST",
        BASE_URL + "/api/auth/login",
        null,
        JSON.stringify(params)
      );

      login(res.data.data.token, res.data.data._id, res.data.data);
      setIsLoading(false);
      history.push("/home");
    } catch (err) {
      setIsLoading(false);
      ShowToast(err.response.data.message, {
        position: "top-right",
        type: "error",
      });
    }
  };

  return (
    <div className="app-container">
      <ToastMessage />
      <div className="form-container">
        <Card className="card" elevation={0}>
          <CardContent>
            <CardMedia component="img" style={{ width: "50%" }} alt="FieldR Logo" src={logo} />
            <h1 className="text-left">{txt.log_in}</h1>
            <p className="text-left">{txt.login_below_text}</p>
            <CustomTextField
              label={txt.email_contact}
              type="text"
              value={emailOrContactNo}
              onChange={(e) => setEmailOrContactNo(e.target.value)}
            />
            {emailOrContactNoError && <p className="error-message">{emailOrContactNoError}</p>}
            <CustomPasswordField
              label={txt.password}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
            <p
              onClick={() => history.push("/forgotPassword")}
              className="text-left forgot-password"
              style={{ padding: 0, marginTop: 0, cursor: "pointer" }}
            >
              {txt.forget_password}
            </p>
            <CardActions className="card-actions">
              <PrimaryButton onClick={handleLogin}>
                {isLoading ? <CircularProgress /> : txt.login}
              </PrimaryButton>
            </CardActions>
            <div className="other-login">
              <div className="line"></div>
              <p>{txt.or_continue_with}</p>
              <div className="line"></div>
            </div>
            <CardActions className="card-actions">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginFailure}
                useOneTap
                shape="circle"
                logo_alignment="center"
                theme="outline"
                locale="en"
              />
            </CardActions>
            <h4 onClick={() => history.push("/signup")}>
              {txt.new_user_register}
            </h4>
          </CardContent>
        </Card>
      </div>
    </div>
  )
};

export default Login;
