export const tempPlayers = [
    {
        firstName: 'Priya',
        lastName: 'Priya',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 115,
        score: 301,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 102,
        score: 299,
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 98,
        score: 200,
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 85,
        score: 199,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 85,
        score: 190,
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 100,
        score: 175,
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 100,
        score: 999,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 100,
        score: '000',
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 100,
        score: '000',
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 100,
        score: 888,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 666,
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'John',
        lastName: 'John',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'Jessi',
        lastName: 'Jessi',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
    {
        firstName: 'Sam',
        lastName: 'Sam',
        battingStyle: "Right-hand",
        bowlingArm: "Right-hand",
        matches: 125,
        score: 356,
    },
]


export const teamsDataTemp = [
    {
        teamName: 'Team 1',
        location: 'sri lanka',
        joindeDate: '2024-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
        createdBy: 1,
    },
    {
        teamName: 'Good Team',
        location: 'sri lanka',
        joindeDate: '2024-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
    },
    {
        teamName: 'Keyur team',
        location: 'America',
        joindeDate: '2025-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
        createdBy: 1,
    },
    {
        teamName: 'Jeel team',
        location: 'pakistan',
        joindeDate: '2024-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
    },
    {
        teamName: 'Keyur team',
        location: 'America',
        joindeDate: '2025-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
        createdBy: 1,
    },
    {
        teamName: 'Jeel team',
        location: 'pakistan',
        joindeDate: '2024-10-04',
        played: 30,
        won: 20,
        lost: 10,
        win: 60,
    },
]

