import { Grid } from '@material-ui/core'
import React from 'react'
import TeamCard from '../../../components/CustomMUI/TeamCard'
import RegisteredTeams from './RegisteredTeams'

function Teams({ tournamentDetails, isAdmin, reloadTournament }) {
  return (
    <div>
      {isAdmin ? (
        <RegisteredTeams tournamentDetails={tournamentDetails} reloadTournament={reloadTournament} />
      ) : (
        <Grid container spacing={2}>
            {tournamentDetails.teamList?.map((team, index) => (
            <Grid item xs={12} key={index}>
              <TeamCard teamData={team} />
            </Grid>
          ))}
        </Grid>
      )}
    </div >
  )
}

export default Teams