import React, { useEffect, useState } from "react";
import { LinearProgress, Tab, Tabs } from "@material-ui/core";
import { sendHttpRequest, BASE_URL } from "../../../common/Common";
import { toast } from "react-toastify";
import Teams from "./Teams";
import Details from "./Details";
import { TabContext, TabPanel } from "@material-ui/lab";
import Header from "../../../components/CustomMUI/Header";
import Fixtures from "./Fixtures";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function Tournament() {
  const { tournamentId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentDetails, setTournamentDetails] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [tabValue, setTabValue] = useState('details');

  useEffect(() => {
    getTournamentDetails(tournamentId);
  }, [tournamentId]);

  function getTournamentDetails(tournamentId) {
    setIsLoading(true);
    sendHttpRequest("GET", BASE_URL + "/api/tournament/" + tournamentId).then(res => {
      setTournamentDetails(res.data.data);
      const checkIsAdmin = (res.data.data.admins && res.data.data.admins.map(admin => admin._id).includes(localStorage.getItem('loggedInUserId'))) || res.data.data.createdBy._id === localStorage.getItem('loggedInUserId');
      setIsAdmin(checkIsAdmin);
    }).catch((error) => {
      toast.error(error.response.data.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) {
    return <LinearProgress />
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function reloadTournament() {
    getTournamentDetails(tournamentId);
  }

  return (
    <div>
      <Header title={tournamentDetails.name} />
      <TabContext value={tabValue}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} textColor="primary" indicatorColor="primary">
          <Tab label="Details" value="details" {...a11yProps(0)} />
          <Tab label="Teams" value="teams" {...a11yProps(1)} />
          <Tab label="Fixtures" value="fixtures" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value="details" style={{ padding: '1rem' }}>
          <Details tournamentDetails={tournamentDetails} refreshData={() => getTournamentDetails()} />
        </TabPanel>
        <TabPanel value="teams" style={{ padding: '1rem' }}>
          <Teams tournamentDetails={tournamentDetails} isAdmin={isAdmin} reloadTournament={() => reloadTournament()} />
        </TabPanel>
        <TabPanel value="fixtures" style={{ padding: '1rem' }}>
          <Fixtures
            fixtureArray={tournamentDetails.fixtures}
            tournament_id={tournamentDetails._id}
            tournament_status={tournamentDetails.status}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Tournament;
