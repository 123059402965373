import React, { useEffect, useState } from "react";
import {
  CustomSmallButton,
  CustomSmallRoundedButton,
} from "./CustomSmallButton";
import "./CustomMUI.css";
import { useHistory } from "react-router-dom";
import RSVPBottomSheet from "./RSVPBottomSheet";
import { BASE_URL, sendHttpRequest } from "../../common/Common";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import editIcon from "../.././assets/images/svg/editIcon.svg"
import deleteIcon from "../.././assets/images/svg/deleteIcon.svg"
import DeleteMatchBottomSheet from "./DeleteMatchBottomSheet";

const MatchCard = ({ data, setSelectedMatchData, isModal, hideDeleteEditBtn }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [showRSVP, setShowRSVP] = useState(false);
  const [showDeleteMatch, setShowDeleteMatch] = useState(false);
  const [selectedRSVP, setSelectedRSVP] = useState();
  const [cardData, setCardData] = useState(data);

  useEffect(() => {
    if (selectedRSVP) {
      let reqData = { ...data, rsvp: selectedRSVP };
      setCardData(reqData)
      updateRSVP(reqData);
    }
  }, [selectedRSVP])

  const updateRSVP = async (reqData) => {
    try {
      const res = await sendHttpRequest(
        "POST",
        `${BASE_URL}/api/match/rsvp`, null, JSON.stringify(reqData)
      );
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="matches-card" onClick={() => {
      if (setSelectedMatchData === undefined) {
        history.push('/my-matches', { selectedMatch: cardData, openModalNo: 1 });
      } else {
        setSelectedMatchData(cardData);
      }
    }}>

      <div className="dateTeamStatusContainer">
        <div>
          <CustomSmallButton name={cardData?.matchDate?.split('-')[2] + " " + months[cardData?.matchDate?.split('-')[1] - 1]} disabled={false} />
        </div>

        <h3 style={{ margin: 0, flex: 1, fontSize: '18px' }}> Vs  {cardData.teamB.name}</h3>

        <div>
          <CustomSmallRoundedButton name={cardData?.rsvp} type="btn-green" />
        </div>
      </div>

      <div className="noOfmatchGroundTournamentNameConatiner">
        {/* <p>nn Match</p> */}
        <p>{cardData?.matchType?.type}</p>
        <p>{cardData?.ground}</p>
        <p>{cardData?.matchName}</p>
      </div>

      {
        !isModal &&

        <>
          {
            cardData?.status !== "NOT-STARTED" &&
            <div className="teamsNameScoreContainer">
              <div>
                <p>{cardData?.inning1?.battingTeam?.name}</p>
                <p>
                  <span>
                    {
                      cardData?.inning1?.runs?.toString().padStart(3, '0') + "/" + cardData?.inning1?.wickets?.toString().padStart(2, '0')
                    }
                  </span>
                </p>
              </div>
              <div className="matchCardopponetTeamScoreCon">
                <p>{cardData?.inning2?.battingTeam?.name}</p>
                <p>
                  {`Target : ${(cardData?.inning1?.runs + 1)?.toString().padStart(3, '0')}`}
                  &nbsp;&nbsp;
                  <span>
                    {
                      cardData?.inning2?.runs?.toString().padStart(3, '0') + "/" + cardData?.inning2?.wickets?.toString().padStart(2, '0')
                    }
                  </span>
                </p>
              </div>
              {
                cardData?.winningTeam?.name &&
                <p className="f1113400">
                  {cardData?.winningTeam?.name} Won by {" "}
                  {cardData?.winningTeam?._id === cardData?.inning1?.battingTeam?._id ? ((cardData?.inning1?.runs + 1) - cardData?.inning2?.runs) : cardData?.inning2?.runs}
                  {" "}Runs
                </p>
              }
            </div>
          }

          <div className="matchCardBtnContainer">
            {/* <button data-content="share">Share</button> */}

            {
              (cardData?.status === "STARTED" && cardData?.createdBy?._id === localStorage.getItem("loggedInUserId")) &&
              <button onClick={(e) => {
                e.stopPropagation();
                if (setSelectedMatchData === undefined) {
                  history.push('/my-matches', { selectedMatch: cardData, openModalNo: 3 });
                } else {
                  setSelectedMatchData(cardData, 3);
                }
              }}>Scoresheet</button>
            }

            {
              (cardData?.status !== "NOT-STARTED" && !pathname.startsWith("/scorecard")) &&
              <button onClick={(e) => {
                e.stopPropagation();
                // history.push('/scoresheet/6626407885ff843b969b4133');
                // history.push('/match/6626407885ff843b969b4133');
                history.push({
                  pathname: `/scorecard/${cardData._id}`,
                  state: { matchData: cardData.otherData }
                });

                // if (setSelectedMatchData === undefined) {
                //   history.push(`/scorecard/${cardData._id}`, { selectedMatch: cardData, openModalNo: 1 });
                // } else {
                //   setSelectedMatchData(cardData);
                // }
              }}
              >Scorecard</button>
            }

            {
              cardData?.status === "NOT-STARTED" &&
              <button onClick={(e) => {
                e.stopPropagation();
                setShowRSVP(true);
              }}>RSVP</button>
            }

          </div>

          {
            (cardData?.status === "NOT-STARTED" && cardData?.createdBy?._id === localStorage.getItem("loggedInUserId") && !hideDeleteEditBtn) &&

            <div className="editDeleteMatchContainer" style={{ display: 'flex', gap: 10, marginTop: 10 }}>
              <CustomSmallButton name={'Hosting'} type="btn-lightyellow" />
              {/* edit match button */}
              <CustomSmallButton
                name={<img height={'18px'} width={'18px'} src={editIcon} alt="" />}
                type="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/updateMatch`, { match: cardData })
                }}
              />
              {/* delete match button */}
              <CustomSmallButton
                name={<img height={'18px'} width={'18px'} src={deleteIcon} alt="" />}
                type="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteMatch(true)
                }}
              />
            </div>
          }

          {
            showRSVP &&
            <RSVPBottomSheet isOpen={showRSVP} onDismiss={() => {
              setShowRSVP(false);
            }} setSelectedRSVP={setSelectedRSVP} />
          }

          {
            showDeleteMatch &&
            <DeleteMatchBottomSheet
              isOpen={showDeleteMatch}
              onDismiss={() => {
                setShowDeleteMatch(false);
              }}
              selectedMatch={cardData}
            />
          }
        </>
      }

    </div>
  );
};
export default MatchCard;
