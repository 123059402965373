import { Card, CardActionArea, CardContent, Dialog, DialogContent, Grid, IconButton } from '@material-ui/core'
import { SettingsOutlined, ShareOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import StartMatchModal from './StartMatchModal'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

function MatchCard(props) {
  const [userId, setUserId] = useState('')
  const [showStartMatchModal, setShowStartMatchModal] = useState(false)

  useEffect(() => {
    setUserId(localStorage.getItem('loggedInUserId'))
  }, [props.history])

  function handleClick() {
    if (props.data.status === "STARTED" && props.data.createdBy._id === userId) {
      props.history.push(`/scoresheet/${props.data._id}`)
    } else if (props.data.status === "COMPLETE" || props.data.status === "STARTED") {
      props.history.push(`/match/${props.data._id}`)
    } else if (props.data.status === "NOT-STARTED" && props.data.createdBy._id === userId) {
      setShowStartMatchModal(true)
    } else {
      toast.error("You can't access this match")
    }
  }

  return (
    <>
      <Card
        elevation={0}
        style={{ border: '1px solid var(--neutral-white)', borderRadius: '8px', boxShadow: '0px 4px 4px 0px #EDE8EC14' }}
      >
        <CardActionArea onClick={() => handleClick()}>
          <CardContent className='py-0'>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {props.data.status === "NOT-STARTED" ? (
                  <p className='text-left text-primary' style={{ fontSize: '22px', fontWeight: 400 }}>{props.data.teamA.name}</p>
                ) : (
                  <p className='text-center'>{props.data.teamA.name}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                {props.data.status === "NOT-STARTED" ? (
                  <p className='text-right text-primary' style={{ fontSize: '22px', fontWeight: 400 }}>{props.data.teamB.name}</p>
                ) : (
                  <p className='text-center'>{props.data.teamB.name}</p>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <div style={{ background: 'var(--neutral-white)' }}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={8}>
              <CardActionArea onClick={() => handleClick()}>
                <CardContent className='py-0'>
                  <p className='text-info mb-0'>{props.data.matchDate}</p>
                  <p className='my-0' style={{ fontSize: '18px', fontWeight: 400 }}>{props.data.matchName}</p>
                  <p className='mt-0 text-secondary'>{props.data.ground}</p>
                </CardContent>
              </CardActionArea>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`https://indoor.fieldr.lk/match/${props.data._id}`);
                  toast.success("Link copied to clipboard")
                }}
              >
                <ShareOutlined fontSize='small' />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton disabled hidden={props.data.createdBy !== userId}>
                <SettingsOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Dialog fullWidth open={showStartMatchModal} onClose={() => setShowStartMatchModal(false)}>
        <DialogContent>
          <StartMatchModal
            data={props.data}
            onClose={() => setShowStartMatchModal(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withRouter(MatchCard)