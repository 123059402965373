import React, { useState } from 'react'
import Header from '../CustomMUI/Header'
import { Card, CardContent } from '@material-ui/core'
import { CustomActiveDiactiveButtonWithShadow } from '../CustomMUI/CustomButtons'
import TeamInfoCard from '../TeamInfoCard/TeamInfoCard'
import TeamFullInfoModal from './TeamFullInfoModal'
import { teamsDataTemp } from './tempData'

const MyTeamModal = () => {
    const teamsType = [
        "All",
        "My Teams",
        "Part of"
    ]

    const [selectedTeamsType, setSelectedTeamsType] = useState(teamsType[0]);
    const [selectedTeam, setSelectedTeam] = useState(false);

    return (
        <div className='pageMainContainer'>

            {
                !selectedTeam ?
                    <>
                        <Header title={'My Teams'} />

                        <Card className='px-15' elevation={0} style={{ background: '#fff', paddingBottom: '10px' }}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <div style={{ position: 'sticky', top: 0, border: '1px solid transparent', zIndex: 2 }}>
                                    <p className="text-left text-color">Below are the teams you own and part of for your preview.</p>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                        {
                                            teamsType?.map((type, idx) => (
                                                <CustomActiveDiactiveButtonWithShadow
                                                    key={idx}
                                                    title={type}
                                                    isActive={selectedTeamsType === type}
                                                    onClick={() => setSelectedTeamsType(type)}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <Card elevation={0} style={{ height: '100%', background: '#fff', }}>
                            <CardContent className='hide-scrollbar' style={{ paddingTop: 0, paddingBottom: 0, height: '100%', overflowY: 'scroll' }}>
                                <div style={{ padding: '15px 0', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                    {
                                        teamsDataTemp?.map((team, idx) => (
                                            <TeamInfoCard
                                                key={idx}
                                                teamInfo={team}
                                                setSelectedTeam={setSelectedTeam}
                                            />
                                        ))
                                    }
                                </div>
                            </CardContent>
                        </Card>
                    </>
                    :
                    <TeamFullInfoModal selectedTeam={selectedTeam} closeModal={() => setSelectedTeam(null)} />
            }
        </div>
    )
}

export default MyTeamModal