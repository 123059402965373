import React from 'react'
import Header from '../CustomMUI/Header'
import { handlePayment } from '../../utils/razorpayUtils'

const More = () => {
  return (
    <div className="pageMainContainer">
      <Header title={'More'} />

      <button onClick={() => handlePayment(500,'INR','epsilon','dev@gmail.com','0761853456')}>Pay Now</button>
    </div>
  )
}

export default More
