import React from 'react';
import './CustomMUI.css'
import { Button } from '@material-ui/core';

export function PrimaryButton(props) {
  const { className, children, ...otherProps } = props;
  return (
    <Button
      disableElevation 
      variant='contained' 
      fullWidth 
      size='large'
      type={props.type}
      className={className + ' primary-btn' || 'primary-btn'}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export function SecondaryButton(props) {
  const { className, children, ...otherProps } = props;
  return (
    <Button
      disableElevation variant='outlined' fullWidth
      className={className + ' primary-hollow-btn' || 'primary-hollow-btn'}
      startIcon={props.startIcon}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export function DeleteButton(props) {
  const { onClick, className, children } = props;
  return (
    <Button
      disabled={props.disabled}
      onClick={onClick}
      disableElevation
      className={className + ' delete-btn' || 'delete-btn'}
      variant='outlined'
    >
      {children}
    </Button>
  );
};

export function LightButton(props) {
  const { className, children, ...otherProps } = props;
  return (
    <Button
      disableElevation
      variant='contained'
      fullWidth={props.fullWidth}
      type={props.type}
      className={className + ' light-btn' || 'light-btn'}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export function CustomIconButton({ children, style, onClick }) {
  return (
    <Button
      onClick={onClick}
      style={{
        border: 'none',
        background: 'var(--bg2)',
        padding: '0px',
        width: 'max-content',
        minWidth: 'max-content',
        ...style
      }}
    >
      {children}
    </Button>
  );
};


export function CustomActiveDiactiveButtonWithShadow({ title, style, onClick, isActive }) {

  return (
    <Button
      className='f1113400'
      onClick={onClick}
      style={{
        border: 'none',
        background: isActive ? 'var(--netural-color-900)' : 'var(--bg1)',
        padding: '3px 8px',
        borderRadius: '8px',
        color: isActive && 'white',
        textTransform: 'capitalize',
        boxShadow: 'var(--shadow1)',
        ...style
      }}
    >
      {title}
    </Button>
  );
};