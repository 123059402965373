import React, { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import CloseIcon from "../../assets/images/svg/close.svg";
import RedBall from "../../assets/images/score/Red_Ball.svg";
import { txt } from "../../common/context";
import { PrimaryButton } from "../CustomMUI/CustomButtons";
import CustomCard from "./../CustomMUI/CustomCard";
import { Box, Tab, Tabs } from "@material-ui/core";

const CustomNextBatterBottomSheet = ({
  isOpen,
  onDismiss,
  setSelectedBatter,
  allBatsmen
}) => {
  const [selectedPlayerIdx, setSelectedPlayerIdx] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onDismiss}
    >
      <div className="bottomSheetContainer">
        <div className="header">
          <img src={RedBall} alt="Ball" />
          <img
            src={CloseIcon}
            alt="Close"
            className="close-icon"
            onClick={onDismiss}
          />
        </div>
        <div className="content">
          <p className="sheet-header-text">{txt.next_batter}</p>
          <p className="sheet-description-text">{txt.pick_the_next_batter}</p>

          <Box sx={{ mx: 'auto', mb: 1 }}>
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              onChange={(e, newTab) => setSelectedTab(newTab)}
              centered>
              <Tab label={'STRIKER'} value={1} />
              <Tab label={'NON-STRIKER'} value={0} />
            </Tabs>
          </Box>

          <div style={{ height: '300px', overflowY: 'scroll' }}>
            <div className="player-card-details">
              {allBatsmen?.map((item, idx) => (
                <div key={idx}>
                  <CustomCard
                    player={item?.player}
                    name={selectedPlayerIdx === idx ? "Selected" : "Select"}
                    onClick={() => setSelectedPlayerIdx(idx)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="button-container">
            <PrimaryButton
              onClick={() => {
                if (typeof selectedPlayerIdx === "number") {
                  onDismiss();
                  setSelectedBatter([allBatsmen[selectedPlayerIdx], selectedTab]);
                }
              }}
            >
              {txt.continue}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default CustomNextBatterBottomSheet;
