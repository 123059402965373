import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, InputAdornment, LinearProgress, Tab, Tabs } from "@material-ui/core";
import CustomTextField from "../CustomMUI/CustomTextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { txt } from "../../common/context";
import Header from "../CustomMUI/Header";
import { CustomAddPlayerButton } from "../CustomMUI/CustomSmallButton";
import { BASE_URL, sendHttpRequest } from "../../common/Common";
import { toast } from "react-toastify";
import TeamCard from "../CustomMUI/TeamCard";
import { SearchOutlined } from "@material-ui/icons";
import Chairs from "../../assets/images/svg/chairs.svg";

const SelectTeam = ({ title, showAllTeams, setSelectedTeam }) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(txt.my_teams);
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem('loggedInUserId');
      let response;

      if (selectedTab === txt.my_teams) {
        response = await sendHttpRequest("GET", BASE_URL + "/api/team/own/" + userId);
      } else if (selectedTab === txt.previous) {
        response = await sendHttpRequest("GET", BASE_URL + "/api/team/previous/" + userId);
      } else if (selectedTab === txt.global) {
        response = await sendHttpRequest("GET", BASE_URL + "/api/team/global/" + userId);
      }

      setTeams(response.data.data);
      setFilteredTeams(response.data.data);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error.response)
    }
  };

  const searchTeams = (searchText) => {
    setFilteredTeams(
      teams.filter((team) =>
        team.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  return (
    <div>
      <Header title={title || txt.select_your_team} />
      <div className="page-wrapper">
        <p className="text-left text-color">
          {txt.select_an_existing_team_or_create_a_new_team}
        </p>
        <CustomTextField
          type="search"
          onChange={(e) => searchTeams(e.target.value)}
          placeholder={txt.search_team}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            )
          }}
        />
        {showAllTeams && (
          <Tabs
            value={selectedTab} onChange={(e, newTab) => setSelectedTab(newTab)}
            textColor="primary" indicatorColor="primary"
          >
            <Tab label={txt.my_teams} value={txt.my_teams} />
            <Tab label={txt.previous} value={txt.previous} />
            <Tab label={txt.global} value={txt.global} />
          </Tabs>
        )}
        <Card
          elevation={0} className="my-15"
          style={{ borderRadius: 10, backgroundColor: "var(--card-2-bg)" }}
        >
          <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <p>Create a new Team</p>
              <p className="p1">Enter the name and the phone number.</p>
              <CustomAddPlayerButton
                name={txt.create_team}
                onClick={() => history.push("/create-team")}
              />
            </div>
            <img src={Chairs} style={{ width: "30%" }} alt='create-team' />
          </CardContent>
        </Card>
        {teams.length === 0 ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={2}>
            {filteredTeams.map((item, index) =>
              <Grid key={index} item xs={12}>
                <TeamCard
                  teamData={item}
                  btnTitle='Select'
                  handleAction={(selectedTeamData) => {
                    setSelectedTeam(selectedTeamData)
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div >
  );
};

export default SelectTeam;