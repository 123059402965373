import React, { useEffect, useState } from 'react'
import Header from '../CustomMUI/Header'
import { Card, CardContent, CircularProgress, Tab, Tabs } from '@material-ui/core'
import { txt } from '../../common/context';
import CustomCard from '../CustomMUI/CustomCard';
import { tempPlayers } from './tempData';
import { BASE_URL, sendHttpRequest } from '../../common/Common';
import MatchCard from '../CustomMUI/MatchCard';
import { CustomActiveDiactiveButtonWithShadow } from '../CustomMUI/CustomButtons';
import PlayerCardWithScore from '../PlayerCardWithNumber/PlayerCardWithScore';

const TeamFullInfoModal = ({ selectedTeam, closeModal }) => {

  const [selectedTab, setSelectedTab] = useState(0);
  const [matches, setMatches] = useState([]);
  const [loadMatches, setLoadMatches] = useState(true);

  const leaderboardType = [
    "Most Runs",
    "Most Wickets",
    "Most 6’s",
    "Most 4’s",
    "Most Catches",
    "Most Stumps",
    "Most Runouts",
  ]

  const [activeLeaderboardType, setActiveLeaderboardType] = useState(leaderboardType[0]);

  useEffect(() => {
    getPlayersMatches();
  }, []);

  const getPlayersMatches = () => {
    sendHttpRequest(
      "GET",
      `${BASE_URL}/api/match/user/${localStorage.getItem("loggedInUserId")}` // change to selected team id api
    )
      .then(async (res) => {
        setMatches(res.data.data);
        setLoadMatches(false)
      })
      .catch((error) => {
        // toast.error(error.response.data.message);
      });
  };

  return (
    <div style={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
      <Header
        title={selectedTeam?.teamName}
        isModal={true}
        closeModal={closeModal}
      />

      <Tabs
        style={{ position: 'sticky', top: -1, background: '#fff', zIndex: 2, paddingBottom: '2px' }}
        value={selectedTab}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        onChange={(e, newTab) => setSelectedTab(newTab)}
      >
        <Tab label={txt.Players} />
        <Tab label={txt.Matches} />
        <Tab label={txt.Statistics} />
        <Tab label={txt.Suggestions} />
        <Tab label={txt.Leaderboard} />
      </Tabs>

      <Card elevation={0} style={{ flex: 1, overflowY: 'scroll', }}>
        {
          selectedTab === 0 ? (
            <div className='pt-15 px-15'>
              {
                tempPlayers?.map((player, idx) => (
                  <CustomCard key={idx} player={player} name={'View'} />
                ))
              }
            </div>
          ) : selectedTab === 1 ? (
            <div className='px-15' style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '20px 0' }}>
              {/* <h2 className='text-center'>Matches Comming soon</h2> */}
              {
                loadMatches ?
                  <CircularProgress style={{ margin: 'auto' }} />
                  :
                  matches.length === 0 ? (
                    <div className="no-matches">
                      <p style={{ textAlign: 'center' }}>{txt.no_matches}</p>
                    </div>
                  ) : (
                    matches.map(
                      (match, index) => (
                        (<MatchCard data={match} key={index} hideDeleteEditBtn={true} />)
                      )
                    )
                  )
              }
            </div>
          ) : selectedTab === 2 ? (
            <h2 className='text-center'>Statistics Comming soon</h2>
          ) : selectedTab === 3 ? (
            <h2 className='text-center'>Suggestions Comming soon</h2>
          ) : (
            <div style={{ height: '100%', overflowY: 'scroll', position: 'relative' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', padding: '15px 0', position: 'sticky', top: -1, background: '#fff', zIndex: 2 }}>
                {
                  leaderboardType?.map((type, idx) => (
                    <CustomActiveDiactiveButtonWithShadow
                      key={idx}
                      title={type}
                      isActive={activeLeaderboardType === type}
                      onClick={() => setActiveLeaderboardType(type)}
                    />
                  ))
                }
              </div>

              <div className='px-15'>
                {
                  tempPlayers?.map((playerInfo, idx) => (
                    <PlayerCardWithScore key={idx} playerInfo={playerInfo} indexNum={idx} />
                  ))
                }
              </div>
            </div>
          )
        }
      </Card>
    </div>
  )
}

export default TeamFullInfoModal