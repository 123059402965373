import React, { useEffect, useState } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { sendHttpRequest, BASE_URL } from "../../../common/Common";
import { toast } from "react-toastify";
import MatchCard from "../../../components/match/MatchCard";
import { PrimaryButton } from "../../../components/CustomMUI/CustomButtons";
import NoResults from "../../../components/NoResults";

function Fixtures({ fixtureArray, tournament_id, tournament_status }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fixtures, setFixtures] = useState([]);

  useEffect(() => {
    if (tournament_status !== 'NOT-STARTED') {
      getFixtures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getFixtures() {
    try {
      setIsLoading(true);
      const promises = fixtureArray.map(async (fixture) => {
        const data = await sendHttpRequest("GET", BASE_URL + "/api/match/id/" + fixture);
        return data.data.data;
      });

      const fixtureDataArray = await Promise.all(promises);
      setFixtures(fixtureDataArray);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <LinearProgress />
  }

  if (tournament_status === 'NOT-STARTED') {
    return <NoResults text='fixtures' />
  }

  function endTournament() {
    setIsLoading(true);
    sendHttpRequest("PUT", BASE_URL + "/api/tournament/end/" + tournament_id).then(res => {
      toast.success(res.data.message);
    }).catch((error) => {
      toast.error(error.response.data.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div>
      <Grid container spacing={2}>
        {fixtures.map((match, index) => (
          <Grid item xs={12} key={index}>
            <MatchCard data={match} isTournament={true} />
          </Grid>
        ))}
      </Grid>
      {(fixtures.length > 0 && fixtures.every(match => match.status === "COMPLETED")) && (
        <PrimaryButton onClick={() => endTournament()}>End Tournament</PrimaryButton>
      )}
    </div>
  );
}

export default Fixtures;